// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.resHeader {
    margin-left: -10%;
}

.skillWrapper {
    display: flex;
    flex-direction: row;
    background: #36b8c9;
    height: 60vh;
    font-family: "Josefin Sans";
    justify-content: space-between;
    border: solid 2px #32717f;
    box-shadow: 0px 5px #32717f;
    border-radius: 50px;
    margin-left: 20%;
    margin-right: 20%;
    margin-bottom: 2%;
    padding-left: 2%;
    padding-right: 2%;
    padding-top: 1%;
    padding-bottom: 1%;
}

.resColumn {
    border: solid 2px silver;
    width: 45%;
    border-radius: 50px;
    padding: 2%;
    color: white;
    box-shadow: 0px 5px silver;
    background: #32717f;
}

.skillColumn {
    border: solid 2px silver;
    box-shadow: 0px 5px silver;
    overflow-y: auto;
    width: 45%;
    border-radius: 50px;
    padding: 2%;
    color: white;
    background: #32717f;
    scrollbar-width: thin;
    scrollbar-color: #15474e #32717f;
}

.resUL {
    list-style-type: none;
}`, "",{"version":3,"sources":["webpack://./src/components/skills.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,YAAY;IACZ,2BAA2B;IAC3B,8BAA8B;IAC9B,yBAAyB;IACzB,2BAA2B;IAC3B,mBAAmB;IACnB,gBAAgB;IAChB,iBAAiB;IACjB,iBAAiB;IACjB,gBAAgB;IAChB,iBAAiB;IACjB,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,wBAAwB;IACxB,UAAU;IACV,mBAAmB;IACnB,WAAW;IACX,YAAY;IACZ,0BAA0B;IAC1B,mBAAmB;AACvB;;AAEA;IACI,wBAAwB;IACxB,0BAA0B;IAC1B,gBAAgB;IAChB,UAAU;IACV,mBAAmB;IACnB,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,qBAAqB;IACrB,gCAAgC;AACpC;;AAEA;IACI,qBAAqB;AACzB","sourcesContent":[".resHeader {\n    margin-left: -10%;\n}\n\n.skillWrapper {\n    display: flex;\n    flex-direction: row;\n    background: #36b8c9;\n    height: 60vh;\n    font-family: \"Josefin Sans\";\n    justify-content: space-between;\n    border: solid 2px #32717f;\n    box-shadow: 0px 5px #32717f;\n    border-radius: 50px;\n    margin-left: 20%;\n    margin-right: 20%;\n    margin-bottom: 2%;\n    padding-left: 2%;\n    padding-right: 2%;\n    padding-top: 1%;\n    padding-bottom: 1%;\n}\n\n.resColumn {\n    border: solid 2px silver;\n    width: 45%;\n    border-radius: 50px;\n    padding: 2%;\n    color: white;\n    box-shadow: 0px 5px silver;\n    background: #32717f;\n}\n\n.skillColumn {\n    border: solid 2px silver;\n    box-shadow: 0px 5px silver;\n    overflow-y: auto;\n    width: 45%;\n    border-radius: 50px;\n    padding: 2%;\n    color: white;\n    background: #32717f;\n    scrollbar-width: thin;\n    scrollbar-color: #15474e #32717f;\n}\n\n.resUL {\n    list-style-type: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
