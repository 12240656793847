// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footwrapper {
    position: fixed;
    display: flex;
    justify-content: center;
    background: #3c1f73;
    color: white;
    font-family: "Josefin Sans";
    height: 2%;
    z-index: 10;
    bottom: 0;
    width: 100%;
    height: 2%;
    padding-top: 1%;
    padding-bottom: 1%;
    box-shadow: 0px -5px 5px black;
}

.footText {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    color: white;
    line-height: 0.05em;
    font-size: 0.9em;
}

.footText a {
    text-decoration: underline;
    margin-top: -3%;
}
`, "",{"version":3,"sources":["webpack://./src/components/footer.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,YAAY;IACZ,2BAA2B;IAC3B,UAAU;IACV,WAAW;IACX,SAAS;IACT,WAAW;IACX,UAAU;IACV,eAAe;IACf,kBAAkB;IAClB,8BAA8B;AAClC;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,6BAA6B;IAC7B,mBAAmB;IACnB,YAAY;IACZ,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,0BAA0B;IAC1B,eAAe;AACnB","sourcesContent":[".footwrapper {\n    position: fixed;\n    display: flex;\n    justify-content: center;\n    background: #3c1f73;\n    color: white;\n    font-family: \"Josefin Sans\";\n    height: 2%;\n    z-index: 10;\n    bottom: 0;\n    width: 100%;\n    height: 2%;\n    padding-top: 1%;\n    padding-bottom: 1%;\n    box-shadow: 0px -5px 5px black;\n}\n\n.footText {\n    display: flex;\n    flex-direction: column;\n    justify-content: space-around;\n    align-items: center;\n    color: white;\n    line-height: 0.05em;\n    font-size: 0.9em;\n}\n\n.footText a {\n    text-decoration: underline;\n    margin-top: -3%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
